import React, { useState } from "react"
import { api_base_url, browserstring, getCurrentUser } from "../../utils/auth"
import * as styles from "./main.module.css"
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as Yup from "yup"
import { Link } from "@reach/router"
import useInterval from '@restart/hooks/useInterval'
const axios = require('axios').default;
const {v4: uuidv4} = require('uuid');

const SwishPayment = ({enabled=true}) => {
  const [paymentState, setPaymentState] = useState("IDLE");
  const userdata = getCurrentUser();
  const [requestUid, setRequestUid] = useState("");
  const [paymentuuid, setPaymentuuid] = useState("");

  const is_prod = () => {
    return process.env.MEDIALIKE_ENV === "PRD";
  }

  const MIN_REFILL_AMOUNT = () => {
    return (is_prod() ? 10 : 5);
  }

  const MAX_REFILL_AMOUNT = 1000;

  useInterval(() => {
    const options = {
      method: 'post',
      url: `${api_base_url()}/${userdata.cookie}/swishrequest/${requestUid}/status`,
      data: {
        BrowserData: browserstring(),
        paymentuuid: paymentuuid
      }
    };

    axios(options)
      .then(response => {
        if (response.data.PaymentStatus === "PAID") {
          setPaymentState(response.data.PaymentStatus);
        } else if (response.data.PaymentStatus === "DECLINED") {
          setPaymentState(response.data.PaymentStatus);
        }

      })
      .catch(function (error) {
        setPaymentState("ERROR");
      });

  }, 4000, paymentState !== "WAITING")


  if (paymentState === "IDLE" ) {
    return (
      <div>
        <div className={["container", "mb-1"].join(" ")}>
          <Formik initialValues={{
            amount: 100,
            phone: "",
          }}
                  validationSchema={Yup.object().shape({
                    phone: Yup.string()
                      .matches("^(([+]46)\\s*|0)[1-9]\\s*([\\d\\s]{7,})$", "Du måste ange ett giltigt svenskt telefonnummer")
                      .required("Du måste ange det telefonnummer från vilket du ska skicka Swish-betalningen"),
                    amount: Yup.number().required("Du måste ange ett belopp")
                      .max(MAX_REFILL_AMOUNT, `Maximalt belopp är ${MAX_REFILL_AMOUNT} kronor`)
                      .min(MIN_REFILL_AMOUNT(), `Minsta tillåtna belopp är ${MIN_REFILL_AMOUNT()} kronor`)
                  })}
                  onSubmit={(values, actions) => {

                    const paymentReqUid = uuidv4();
                    setRequestUid(paymentReqUid)

                    let phone = values.phone;
                    phone = phone.replace(/\s/g, "").replace(/-/g, "");

                    const submitOptions = {
                      method: 'post',
                      url: `${api_base_url()}/${userdata.cookie}/swishrequest/${paymentReqUid}`,
                      data: {
                        BrowserData: browserstring(),
                        phonenumber: phone,
                        amount: values.amount
                      }
                    };

                    axios(submitOptions)
                      .then(response => {
                        setPaymentuuid(response.data.uuid);
                        setPaymentState("WAITING");
                      })
                      .catch(function (error) {
                        setPaymentState("ERROR");
                      });
                  }}
          >
            {({ errors, touched, isValidating, isSubmitting }) => (
              <Form>
                <div className="form-group">
                  <label htmlFor="phone">
                    Ange det telefonnummer <span style={{fontWeight: 'bold'}}>från</span> vilket du
                    ska skicka Swish-betalningen. (Ditt eget Swishnummer)</label>
                  <Field type="text" name="phone"
                         className={"form-control" + (errors.phone && touched.phone ? " is-invalid" : "")}/>
                  <ErrorMessage name="phone" component="div" className="invalid-feedback"/>
                </div>
                <div className="form-group">
                  <label htmlFor="amount">Belopp (minst 50 kr men gärna minst 100 kr för att minska behovet av att fylla på ofta)</label>
                  <Field type="text" name="amount"
                         className={"form-control" + (errors.amount && touched.amount ? " is-invalid" : "")}/>
                  <ErrorMessage name="amount" component="div" className="invalid-feedback"/>
                </div>
                <button className={["btn", "btn-warning", "mr-2"].join(" ")} type="submit" disabled={isSubmitting || !enabled}>
                  Starta Swish-betalningen
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    )
  } else if (paymentState === "WAITING") {
    return (
      <div>
        <div className={[styles.borderMedium, "bg-light", "border", "border-info", "container", "mb-1"].join(" ")}>
          <p>Väntar på betalning.</p>

          <p>
          Starta Swish i din mobiltelefon och genomför den väntande betalningen.
          Mottagare av betalningen ska vara MediaLike.</p>
          <div className="spinner-border" role="status">
            <span className="sr-only">Väntar på betalning...</span>
          </div>
          <p>
            Denna sida kommer att uppdateras automatiskt när betalning har genomförts.
          </p>
        </div>
      </div>
    )
  } else if (paymentState === "PAID") {
    return (
      <div>
        <div className={["container", "mb-1"].join(" ")}>
          <p>Tack för påfyllningen av ditt MediaLinq-konto.</p>

          <p>
            Saldot på <Link to="/app/profile/">kontot</Link> har uppdaterats.
          </p>
        </div>
      </div>
    )
  } else if (paymentState === "DECLINED") {
    return (
      <div>
        <div className={["container", "mb-1"].join(" ")}>
          <p>Påfyllningen av ditt MediaLinq-konto avbröts.</p>

          <button type="button" onClick={() => {setPaymentState("IDLE")}} className={["btn", "btn-warning"].join(" ")}>Gör ett nytt försök</button>
          &nbsp;eller&nbsp;
          <Link className={["btn", "btn-info"].join(" ")} to="/app/profile/">Tillbaka till MediaLinq-kontot</Link>

        </div>
      </div>
    )
  } else if (paymentState === "ERROR") {
    return (
      <div>
        <div className={["container", "mb-1"].join(" ")}>
          <p>Påfyllningen av ditt MediaLinq-konto misslyckades.</p>

          <button type="button" onClick={() => {
            setPaymentState("IDLE")
          }} className={["btn", "btn-warning"].join(" ")}>Gör ett nytt försök
          </button>
          &nbsp;eller&nbsp;
          <Link className={["btn", "btn-info"].join(" ")} to="/app/profile/">Tillbaka till MediaLinq-kontot</Link>

        </div>
      </div>
    )
  }
}

export default SwishPayment
